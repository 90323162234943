const appConfig = {
    //apiPrefix: 'https://localhost:8443/galpha',
 // apiPrefix: 'http://localhost:8080/galpha',
    //apiPrefix: 'http://127.0.0.1:8080/galpha',
  apiPrefix: 'https://app.geracaoalphaoficial.com.br/galpha',
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'pt-br',
    enableMock: false,
}
/*const appConfig = {
    apiPrefix: '/api',
    authenticatedEntryPath: '/app/sales/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/app/account/kyc-form',
    locale: 'en',
    enableMock: true,
}*/
export default appConfig;
