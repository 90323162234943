import { createSlice } from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
    name: 'auth/session',
    initialState: {
        token: '',
        signedIn: false,
        sessionStartTime: null, // Novo campo para armazenar a hora de início da sessão
        isLoginDialogVisible: false // Novo campo para controlar a exibição do diálogo

    },
    reducers: {
        onSignInSuccess: (state, action) => {
            state.signedIn = true
            state.token = action.payload//.token // Supondo que o token venha no payload
           state.sessionStartTime = new Date().toISOString() // Armazena a hora atual no formato ISO
           state.isLoginDialogVisible = false
        },
        onSignOutSuccess: (state) => {
            state.signedIn = false
            state.token = ''
            state.sessionStartTime = null // Reseta o tempo da sessão ao deslogar
            state.isLoginDialogVisible = false // Esconde o diálogo ao deslogar
       },
        setToken: (state, action) => {
            state.token = action.payload
        },
        showLoginDialog: (state) => {
            state.isLoginDialogVisible = true // Exibe o diálogo
        },
        hideLoginDialog: (state) => {
            state.isLoginDialogVisible = false // Esconde o diálogo
          //  window.location.reload();

        },
    },
})

export const { onSignInSuccess, onSignOutSuccess, setToken, showLoginDialog, hideLoginDialog } = 
    sessionSlice.actions

export default sessionSlice.reducer
