import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignIn, apiSignOut, apiSignUp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { apiSalvarCoordenador, apiSalvarEspecialista, apiSalvarProfessor, apiSalvarResponsavel } from 'services/NoAuthService';
import { Notification } from 'components/ui'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async (values) => {
        try {
            const resp = await apiSignIn(values);

            if (resp) {
                dispatch(onSignInSuccess({signedIn: true}, {payload: "token"}));
                resp.data.authority = [resp.data.perfil];
                dispatch(setUser(resp.data))
            }
           
            const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
            return {
                status: 'success',
                message: '',
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: "Não foi possível fazer o login"
            }
        }
    }

    const signUp = async (values) => {
        try {
console.log("numero do perfil a criar: ", values.perfil);
            switch (values.perfil) {
        
                case 5: 
                  apiSalvarProfessor(values).then(() => {
                  //  <Notification title='Usuário salvo!' type='success'>Perfil de Professor salvo com sucesso!</Notification>
                  })
                  .catch(() => {
                //    <Notification title='Erro!' type='danger'>Não foi possível criar o usuário!</Notification>
                
                  });
                  break;
          
                case 2: 
                  apiSalvarResponsavel(values)
                  .then(() => {
                 //   <Notification title='Usuário salvo!' type='success'>Perfil de Responsavel salvo com sucesso!</Notification>
                  })
                  .catch(() => {
                 //   <Notification title='Erro!' type='danger'>Não foi possível criar o usuário!</Notification>
                
                  });
                  break;
          
                  case 4: 
                  apiSalvarEspecialista(values)
                  .then(() => {
                 //   <Notification title='Usuário salvo!' type='success'>Perfil de Especialista salvo com sucesso!</Notification>
                  })
                  .catch(() => {
                  //  <Notification title='Erro!' type='danger'>Não foi possível criar o usuário!</Notification>
                
                  });
                  break;
                  case 1: 
                  apiSalvarCoordenador(values)
                  .then(() => {
                  // <Notification title='Usuário salvo!' type='success'>Perfil de Coordenador salvo com sucesso!</Notification>
                  })
                  .catch(() => {
                  //  <Notification title='Erro!' type='danger'>Não foi possível criar o usuário!</Notification>
                  });
                  break;

                default : 
                //<Notification title='Erro!' type='alert'>Funcionalidade não disponível!</Notification>
                break;
                }

                setTimeout(() => {
                  const redirectUrl = query.get(REDIRECT_URL_KEY);
                  navigate(redirectUrl ? redirectUrl : appConfig.unAuthenticatedEntryPath);
              }, 3000);
                return {
                    status: 'success',
                    message: '',
                }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        await apiSignOut()
        handleSignOut()
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
    }
}

export default useAuth
