import axios from 'axios'
import appConfig from 'configs/app.config'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from 'constants/api.constant'
import { PERSIST_STORE_NAME } from 'constants/app.constant'
import deepParseJson from 'utils/deepParseJson'
import store from '../store'
import { onSignOutSuccess, showLoginDialog } from '../store/auth/sessionSlice'

const unauthorizedCode = [401]

const BaseService = axios.create({
    timeout: 60000,
    baseURL: appConfig.apiPrefix,
    withCredentials: true,
})

BaseService.interceptors.request.use(
    (config) => {
        const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
        const persistData = deepParseJson(rawPersistData)
        if (persistData != null) {
            const accessToken = persistData.auth.session.token
            // Configuração de cabeçalho omitida, conforme solicitado
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

BaseService.interceptors.response.use(
    (response) => {
        // Verifica se a resposta contém HTML de página de login
       if ( typeof response.data === 'string' && response.data.includes('<!DOCTYPE html>')) {
            // Redireciona para a tela de login e limpa os dados do usuário
         //   localStorage.removeItem(PERSIST_STORE_NAME);
            store.dispatch(showLoginDialog());
           //  window.location.href = '/sign-in';
           console.log("ultima resposta: ");
            console.log("Redirecionado para tela de login devido à sessão expirada");
           //  Retorna um erro para interromper o fluxo normal
          //  return Promise.reject(new Error("Sessão expirada ou não autenticado"));
        }

        return response;
    },
    (error) => {
        const { response } = error;

        if (!response) {
            localStorage.removeItem(PERSIST_STORE_NAME);
            store.dispatch(onSignOutSuccess());
            window.location.href = '/sign-in';
        }

        if (response && unauthorizedCode.includes(response.status)) {
            const currentPath = window.location.pathname;
            if (currentPath !== '/sign-in') {
                localStorage.removeItem(PERSIST_STORE_NAME);
                store.dispatch(onSignOutSuccess());
                window.location.href = '/sign-in';
                console.log("Redirecionado no BaseService devido a status 401");
            }
        }

        return Promise.reject(error);
    }
)

export default BaseService;
