import React from 'react'
import PropTypes from 'prop-types'

const TextEllipsis = ({ text = '', maxTextCount = 0 }) => {
    return (
        <>
            {(text && text.length) > maxTextCount
                ? text.substring(0, maxTextCount - 3) + '...'
                : text}
        </>
    )
}

TextEllipsis.propTypes = {
    text: PropTypes.string,
    maxTextCount: PropTypes.number, // Adicionei o tipo para maxTextCount
}

export default TextEllipsis
