import ApiService from './ApiService'

export async function apiSalvarProfessor(professor) {
    return ApiService.fetchData({
        url: '/rest/init/signup/user',
        method: 'post',
        data: professor,
        headers: {"Content-Type": "application/json"}
    })
}

export async function apiSalvarResponsavel(responsavel) {
    return ApiService.fetchData({
        url: '/rest/init/signup/user',
        method: 'post',
        data: responsavel,
        headers: {"Content-Type": "application/json"}
    })
}

export async function apiSalvarCoordenador(coordenador) {
    return ApiService.fetchData({
        url: '/rest/init/signup/user',
        method: 'post',
        data: coordenador,
        headers: {"Content-Type": "application/json"}
    })
}
export async function apiSalvarEspecialista(especialista) {
    return ApiService.fetchData({
        url: '/rest/init/signup/user',
        method: 'post',
        data: especialista,
        headers: {"Content-Type": "application/json"}
    })
}
export async function apiEditarAvatarUsuario(formData, idUsuario) {
    return ApiService.fetchData({
        method: 'put',
        url: `rest/init/avatar/${idUsuario}`,
        data: formData, // Use formData diretamente aqui
        headers: {
            'Content-Type': 'multipart/form-data'
          }
    });
}

export async function apiGetUsuarioPorId(usuarioId) {
    return ApiService.fetchData({
        url: `/rest/init/${usuarioId}`,
        method: 'get',
        headers: {"Content-Type": "application/json"},
      
    })
}