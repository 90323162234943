import React, { useState } from 'react'
import { Avatar, Input } from 'components/ui'
import { HiOutlineEyeOff, HiOutlineEye } from 'react-icons/hi'

const PasswordInput = (props) => {
    const { onVisibleChange, ...rest } = props

    const [pwInputType, setPwInputType] = useState('password')

    const onPasswordVisibleClick = (e) => {
        e.preventDefault()
        const nextValue = pwInputType === 'password' ? 'text' : 'password'
        setPwInputType(nextValue)
        onVisibleChange?.(nextValue === 'text')
    }

    return (
       
         <div className="relative align-middle">
                 
                 <div className="cursor-pointer text-xl w-auto h-full mx-2 right-2 my-auto items-center flex absolute "
                  
                  onClick={(e) => onPasswordVisibleClick(e)}
              >
                  {pwInputType === 'password' ? (
                      <HiOutlineEyeOff />
                  ) : (
                      <HiOutlineEye />
                  )}
              </div>           
        <Input
            {...rest}
            type={pwInputType}
           
        />
        
        </div>
    )
}

export default PasswordInput
